import React from "react";
import Slider from "react-slick";
import bg3 from "../assect/images/bg/03.webp";
import Navbar from "../components/navbar";
import AboutUs from "../components/about";
import Footer from "../components/footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Sell = () => {
  const awards = [
    {
      id: 1,
      image:
        "https://www.bidli.cz/globaldata/users_fotografie/b99531776ab9288b1af1999f11a83b0d.png",
    },
    {
      id: 2,
      image:
        "https://www.bidli.cz/globaldata/users_fotografie/b929f7ba76495f7fd26467f41c879673.png",
    },
    {
      id: 3,
      image:
        "https://www.bidli.cz/globaldata/users_fotografie/eed3529dcca3516fdc1b175f7df57498.png",
    },
    {
      id: 4,
      image:
        "https://www.bidli.cz/globaldata/users_fotografie/eb9cb4881bc4e89eaa77add149466627.png",
    },
  ];
  const testimonials = [
    {
      date: "08.02.2025",
      content:
        "Spolupráce na prodeji nemovitosti s paní Zářeckou byla příjemná a profesionální. Byla jsem velmi spokojená. Od prvního setkání, přes focení nemovitosti až po závěrečné předání mě aktivně informovala o aktuálním dění. Byla jsem naprosto klidná, že se vše děje jak nejlépe může. Služby paní Zářecké a Bidli mohu doporučit. ",
      author: "Hana Hrdinová",
    },
    {
      date: "29.05.2024",
      content:
        "Se vším co jsem potřeboval vyřešit mě vyšla vstříc a snažila se mi hned vyhovět a vše zařídit a domluvit. Vážím si její ochoty, i když sem jí občas otravoval pořád, jako například před týdnem, když sem zjistil, že pozemek který sem koupil není okolíkován tudíž sem nevěděl, kde je hranice. I to se povedlo po mém otravovaní zařídit a vážím si toho, že na čem jsme se domluvily to vždy platilo. Tímto tedy hodnotím velice kladně její práci. Přeji hezký zbytek dne.",
      author: "Jiří Procházka",
    },
    {
      date: "23.04.2024",
      content:
        "Dodatečně bych chtěl moc poděkovat za Vaše služby a ochotu při vyřizování prodeje pozemku v obci Březno u Mladé Boleslavi. S Vašimi službami jsem byl velice spokojen a těším se na jakoukoliv případnou další spolupráci.",
      author: "Jiří Kristek",
    },
    {
      date: "15.11.2023",
      content:
        "Dobrý den, rádi bychom poděkovali za perfektní a profesionální přístup při koupi nemovitosti v Hradci Králové paní Petře Zářecké. Celý proces od první prohlídky proběhl rychle a vše bylo podle našich představ. Určitě bychom se při nákupu/prodeji nemovitosti na paní Zářeckou obrátili i v budoucnu.",
      author: "Jana a Tomáš Sommer",
    },
    {
      date: "12.07.2023",
      content:
        "Dobrý den, ráda bych napsala pozitivní recenzi na spolupráci s realitní makléřkou Petrou Zářeckou. Jako bývalou spolužačku jsem ji oslovila s žádostí o pomoc při hledání nových nájemníků do bytu. Sama jsem neměla chuť ani čas řešit inzerát a Petra reagovala okamžitě. Ihned pochopila jaké požadavky na nové nájemníky mám a během jednoho dne mi připravila troje zájemce na osobní schůzku. Velmi oceňuji její přístup, na schůzku dorazila dříve, abychom si vše ujasnily na místě, prohlídky prováděla velmi zkušeně a stále s úsměvem. Věřím, že hlavně díky jejím zkušenostem jsem našla příjemné nájemníky a určitě se na ní bez obav a ráda zase obrátím.",
      author: "Kristýna Hradecká",
    },
    {
      date: "11.07.2023",
      content:
        "Dobrý den, ráda bych vyjádřila poděkování paní Petře Zářecké za pomoc s prodejem mého bytu v Pardubicích, Polabinách II. Prodej se uskutečnil začátkem tohoto roku a já mám konečně dostatek prostoru dohnat resty, jako například právě vyjádření díků. Paní Zářecká byla velmi ochotná, činná, měla velkou snahu a nakonec i přes nepřízně doby vyjednala velmi slušné podmínky. Vše proběhlo v pořádku, a když nastala menší komplikace, učinila okamžité kroky k nápravě. Myslím, že je velmi schopnou makléřkou, empatickým člověkem na svém místě a ráda bych ji tímto podpořila v její činnosti, kterou dělá maximálně pečlivě a s citem. Zároveň děkuji za profesionální přístup společnosti Bidli.cz. Tuto realitní společnost budu ráda a s čistým svědomím doporučovat dále.",
      author: "Pavla Kulhavá",
    },
    {
      date: "08.01.2023",
      content:
        "Touto cestou bych ráda ohodnotila služby a přístup paní Petry Zářecké, která nám zprostředkovala koupi bytu v Praze - Na Petřinách. S paní Zářeckou jsme od první prohlídky bytu až ku samotnému podpisu rezervační i kupní smlouvy neměli žádný problém. Byla profesionální, stále k dispozici, ochotná zodpovědět všechny naše otázky a o každém kroku nás informovala. Pani Zářecká má velmi příjemné vystupování, je přátelská a dá se s ní na všem dohodnout.",
      author: "Katarína Mendová",
    },
    {
      date: "13.09.2022",
      content:
        "Rád bych se touto cestou podělil o zkušenost s prací realitní makléřky, paní Petry Zářecké. S paní Zářeckou jsem navázal spolupráci během obchodního případu, kdy jsem sám jako makléř osobně zastupoval klientku ve věci prodeje horského domu v Krkonoších. Paní Zářecká zastupovala společnost BIDLI, jež objekt kupovala. Celý proces koupě/prodeje této nemovitosti vyžadoval mnoho úsilí vzhledem ke složitější povaze celého případu. Díky bleskově rychlým reakcím paní Zářecké vše proběhlo hladce a profesionálně.",
      author: "Daniel Čep",
    },
    {
      date: "30.09.2021",
      content:
        "Paní Zářecká stejně jako pan Svoboda byli velmi milí, ochotní a spolehliví. Jsem si jistá, že nebudu v případě potřeby váhat oslovit oba profesionály znovu.",
      author: "Hana Malá",
    },
    {
      date: "29.09.2021",
      content:
        "Celá koupě nemovitosti (ještě musíme doladit předávací protokol) proběhla naprosto v pořádku. Paní Zářecká byla vždy ochotná poradit, zvedat telefony i mimo běžnou pracovní dobu a na všem jsme se byli schopni vždy operativně poradit. Paní Zářeckou jako makléřku doporučuji.",
      author: "Michal Kos",
    },
    {
      date: "27.09.2021",
      content:
        "Chtěl bych napsat krátkou referenci na paní Zářeckou, která nám zprostředkovává prodej bytu v Pardubicích. Velmi aktivní, příjemně vystupující, vždy pozitivně naladěná realitní makléřka. Díky skvělé spolupráci přemýšlíme, že v budoucím roce ji necháme zprostředkovat prodej našeho bytu.",
      author: "Martin Beránek",
    },
    {
      date: "26.09.2021",
      content:
        "S prací paní Zářecké jsme byli velmi spokojeni, už od začátku se nám ve všem snažila vyhovět a vyjít vstříc, kdykoliv bylo něco třeba řešit. Jednání s ní bylo vždy seriózní a v přátelském duchu. K prodeji naší nemovitosti se postavila velmi zodpovědně, velmi se nám líbil i její nápad na propagační video k nemovitosti i jeho zpracování. Díky ní se nemovitost podařilo prodat za vyšší sumu, než jsme původně předpokládali.",
      author: "Vojtěch Malý",
    },
    {
      date: "24.09.2021",
      content:
        "Paní Zářeckou můžu doporučit. Odborné i lidské kvality má. V porovnání s konkurencí propastný rozdíl.",
      author: "Daniel Línek",
    },
    {
      date: "24.06.2021",
      content: "Se spoluprací jsem byl spokojený.",
      author: "Jaroslav Vácha",
    },
    {
      date: "17.06.2021",
      content:
        "S paní Zářeckou jsme byli spokojeni, určitě bychom ji doporučili. Byla vstřícná, komunikovala i ve večerních hodinách a o víkendech. Příjemná spolupráce.",
      author: "Lucie Randáková",
    },
    {
      date: "02.06.2021",
      content:
        "Jednalo se o komplikovanější prodej chaty. Paní Zářecká jasně a pružně komunikovala, poradila, zavolala, podala pomocnou ruku. Služby hradecké pobočky Bidli můžeme jen doporučit!",
      author: "Marie Vaňásková",
    },
    {
      date: "19.05.2021",
      content:
        "S paní Zářeckou jsme byli velmi spokojeni. Rozhodně budeme spolupracovat i nadále. Je velmi schopná a ochotná. Můžeme ji jenom a pouze doporučit.",
      author: "Libor Jeřábek",
    },
    {
      date: "18.05.2021",
      content:
        "S paní Zářeckou jsme byli maximálně spokojeni v oblasti prodeje naší nemovitosti.",
      author: "Michael Kohoutek",
    },
    {
      date: "19.03.2021",
      content:
        "Paní Petře Zářecké, která řešila prodej našeho bytu. Vše proběhlo k naší velké spokojenosti. Tuto makléřku bych rozhodně doporučila dalším klientům.",
      author: "Eva Pleskotová",
    },
    {
      date: "12.03.2021",
      content:
        "Paní Zářecká je velmi příjemná a obětavá realitní makléřka se kterou jsem se kdy setkala. Pomáhá mi do dnes a mohu ji jen doporučit.",
      author: "Dana Halamková",
    },
  ];
  const videoData = [
    {
      videoId: "OeUtnBGTvds",
    },
    {
      videoId: "vJ6ul1xDK_Q",
    },
    {
      videoId: "qVR3SOwZcbA",
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const testimonialSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-light"
      />

      {/* Hero Section */}
      <section
        className="bg-half-120 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2" />
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <p className="text-white-50 para-desc mx-auto mb-0">
                  POMŮŽU VÁM PRODAT NEMOVITOST
                </p>
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Prodávejte snadněji, za co nejlepší cenu.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className="section">
        <div className="container">
          <AboutUs />
        </div>
      </section>

      {/* Video Section */}
      <section className="section bg-gray200">
        <div className="container">
          <div className="row text-center mb-4">
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="title mb-3">
                Jak může vypadat prezentace vaší nemovitosti
              </h4>
            </div>
          </div>
          <div className="row">
            {videoData.map((video, index) => (
              <div className="col-lg-4 col-md-6 mb-4" key={index}>
                <div className="ratio ratio-16x9">
                  <iframe
                    src={`https://www.youtube.com/embed/${video.videoId}`}
                    allowFullScreen
                    title={`Video ${index + 1}`}
                  ></iframe>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Awards Section */}
      <section className="section">
        <div className="container">
          <div className="row text-center mb-4">
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="title fw-bold mb-3">OCENĚNÍ</h4>
            </div>
          </div>
          <Slider {...sliderSettings}>
            {awards.map((award) => (
              <div key={award.id} className="text-center">
                <img
                  src={award.image}
                  alt={`Award ${award.id}`}
                  style={{
                    maxWidth: "150px",
                    height: "150px",
                    margin: "0 auto",
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="section bg-light">
        <div className="container">
          <div className="row text-center mb-4">
            <div className="section-title text-center mb-4 pb-2">
              <h4 className="title fw-bold mb-3">REFERENCE</h4>
              <p className="text-muted">Hodnocení a názory mých klientů.</p>
            </div>
          </div>
          <Slider {...testimonialSettings}>
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="p-3"
                style={{
                  boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                  borderRadius: "12px",
                  backgroundColor: "#fff",
                  margin: "0 10px",
                  height: "100%",
                }}
              >
                <p className="text-muted small">{testimonial.date}</p>
                <p className="mb-2" style={{ lineHeight: "1.5" }}>
                  {testimonial.content}
                </p>
                <h6 className="fw-bold mt-3">{testimonial.author}</h6>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Sell;
